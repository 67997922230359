import React, {useState, useEffect, useContext, useRef, useCallback} from "react";
import { Layout } from 'layout';
import { Alert } from 'react-bootstrap';
import classes from './UserProfilePage.module.scss'
import { UserProfileData, UserProfileVideo, UserProfileVideoOverlayed } from 'components';
import { Button } from 'sub-components';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from 'app/AuthContext';
import { BsDisplay } from "react-icons/bs";
import { updateUserMetadata, getUserProfile, getUserProjects, processUserProfileData, getTitleAndGoal, calculateProfileCompletion, changeUserProfileAvatar, updateUserProfileStats } from 'lib/user';
import { getUserContents, getContentsByCategory } from 'lib/content';

const UserProfilePage = () => {
	// Loading constants
    const loadingTimeoutRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isProfileView, setIsProfileView] = useState(false);

	const navigate = useNavigate();
	const [pitchVideo, setPitchVideo] = useState({
		videoId: "",
		videoUrl: "",
	});
	const [profileMetadata, setProfileMetadata] = useState({});
	const [ displayedError, setDisplayedError ] = useState('');
	const { authToken, userMetadata, forceRefresh, logout} = useContext(AuthContext);
	const { userId } = useParams();
	const loggedInUserId = userMetadata ? userMetadata.id : "";
	const [showLargeCard, setShowLargeCard] = useState(false);
	// State to track whether the screen size is smaller than 1023px
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1023);
	const profileExplanationVideo = 'https://sj-profile-video-dev.s3.amazonaws.com/new-profile-page-tutorial.mp4'; 

	// useEffect to track window resize events and update screen size
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1023);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

	// Navigate to the video edit page
	const navigateToVideoEdit = () => {
		if (authToken) { 
			navigate('/profile/' + userId + '/video/edit');
		} else {
			// handleSignInClick();
		}
	}

	const loadUserVideo = async () => {
        const { isSuccess: getContentsSuccess, resp: getContentsResp, err: getContentsErr } = await getUserContents(userId, [{ "key": "category", "value": "pitch_video" }], authToken);
		if (getContentsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Content Search || Success", getContentsResp); }
			if (getContentsResp.results != null && getContentsResp.results) {
				setPitchVideo({
					videoId: getContentsByCategory(getContentsResp.results, 'pitch_video')[0].id,
					videoUrl: getContentsByCategory(getContentsResp.results, 'pitch_video')[0].link
				})
			}
		} else if (loggedInUserId === userId){
			setPitchVideo({
				videoId: "",
				videoUrl: profileExplanationVideo
			})
		}
    };

	// Organizes the user's profile data for metadata displayed on their video
	const processProfileMetadata = (profileMetadata) => {
		const {title, goal} = getTitleAndGoal(profileMetadata.tags)
		const processedProfileMetadata = {
			displayName: profileMetadata.display_name,
			location: profileMetadata.location,
			expertise: profileMetadata.tags.find(item => item.type === "expertise")?.categories[0].tags,
			title,
			goal,
			userId,
			community: profileMetadata.community,
		};
		return processedProfileMetadata;
	}

	// Get the user's profile data
	const getPitchVideoData = useCallback(async (userId, isProfileView) => {
		// Check if the user is viewing their own profile
		if (loggedInUserId === userId) { setIsProfileView(true); }

		// Get the user's profile
		const { isSuccess: getProfileSuccess, resp: getProfileResp, err: getProfileErr } = 
			await getUserProfile( userId, [{ "key": "details", "value": "true" }, { "key": "tags", "value": "true" }], authToken );

		if (getProfileSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Search || Success", getProfileResp); }
			// Process/format the user profile data
			getProfileResp.results = processUserProfileData(getProfileResp.results);
			const processedResults = processProfileMetadata(getProfileResp.results);
			// Update the state with the profile data
			setProfileMetadata(processedResults);
			// If the user is viewing their own profile, update the user metadata
			if (isProfileView) { 
				updateUserMetadata(getProfileResp.results.user_avatar, 'user_avatar');
				forceRefresh();
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { 
				console.error("Profile Search || Error", getProfileResp, getProfileErr); 
			}
			if (getProfileResp) {
				// If unauthorized, log out the user's session
				if (getProfileResp.status_code === "UNAUTHORIZED") { 
					logout(); 
				} else if (getProfileResp.status_code === "NOT_FOUND") {
					navigate('/not-found');
				} else {
					setDisplayedError("There was an error retrieving your profile. Please try again later.");
				}
			} else {
				setDisplayedError("There was a fatal error retrieving your profile. Please try again later.");
			}
		}

    	setIsLoading(false);
	}, []);

	/* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
	`useEffect` hook is used to load projects and show a loading spinner if the projects haven't
	loaded after 2 seconds. */
   	useEffect(() => {
		// Only shows the loading spinner if the projects haven't loaded after 2 seconds
		loadingTimeoutRef.current = setTimeout(() => {
			setIsLoading(true);
		}, 2000);
		loadUserVideo();
		isSmallScreen && getPitchVideoData(userId, isProfileView);
	}, [authToken, userId, isProfileView, getPitchVideoData]);

	return (
		<Layout tabHeader="Profile">
                {/* Conditionally render LargeModal only for small screens */}
                {isSmallScreen && (
					<div className={classes.userProfilePageContainer}>
						<UserProfileVideoOverlayed
							pitchVideo={pitchVideo.videoUrl}
							pitchMetadata={profileMetadata}
						/>
						<Alert variant="danger" className="animated-fast fadeInDownMenu">
							<Alert.Heading style={{ fontSize: "13px", color: "red", whiteSpace: "pre-line" }}>
								{displayedError}
							</Alert.Heading>
						</Alert>
						{ userId === loggedInUserId && (
							<Button style={{margin:'auto'}} className="btn-primary btn-lg" onClick={navigateToVideoEdit}> Upload your pitch </Button>
						)}
					</div>
                )}
                {/* For larger screens, render normally */}
                {!isSmallScreen && (
					<div className={classes.userProfilePageContainer}>
						<div className={classes.videoContainer}>
							<UserProfileVideo 
								pitchVideo={pitchVideo.videoUrl}
							/>
							<Alert variant="danger" className="animated-fast fadeInDownMenu">
								<Alert.Heading style={{ fontSize: "13px", color: "red", whiteSpace: "pre-line" }}>
									{displayedError}
								</Alert.Heading>
							</Alert>
							{ userId === loggedInUserId && (
								<Button className="btn-primary btn-lg" onClick={navigateToVideoEdit}> Upload your pitch </Button>
							)}
						</div>
						<div className={classes.dataContainer}>
							<UserProfileData />
						</div>
					</div>
                )}
		</Layout>
	);
};

export default UserProfilePage;
