import React from 'react';
import classes from './Communities.module.scss';
import {SectionHeading, CallToAction} from 'components';
import pslIcon from 'images/psl-icon.png';
import pteIcon from 'images/pte-icon.png';
import fiIcon from 'images/fi-icon.png';
import vcIcon from 'images/vc-icon.png';

const Communities = (props) => {
    const pslUrl = "https://www.phillystartupleaders.org";
    const vcUrl = "https://venturecafephiladelphia.org";
    const pteUrl = "https://phillytechentrepreneurs.com";
    const fiUrl = "https://fi.co";
    
    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div>
            <SectionHeading color="2E4C4D" header="3. Meet up within your local communities." subHeading="Your next match might be closer than you think."/>
            <div className={`${classes.communityCards}`}>
                <div className={`${classes.communityCard}`}>
                    <img src={pslIcon} onClick={() => openNewTab(pslUrl)}/>
                </div>
                <div className={`${classes.communityCard}`}>
                    <img src={vcIcon} onClick={() => openNewTab(vcUrl)}/>
                </div>
                <div className={`${classes.communityCard}`}>
                    <img src={pteIcon} onClick={() => openNewTab(pteUrl)}/>
                </div>
                <div className={`${classes.communityCard}`}>
                    <img src={fiIcon} onClick={() => openNewTab(fiUrl)}/>
                </div>
            </div>
            <SectionHeading subHeading="Are you a community leader? We'd love to enhance your events!"/>
            <CallToAction 
                userActionButtonTitle="Let's talk" 
                imageDesc="sidejawn partners connecting through startup"
            />
        </div>
    );
    };

export default Communities;