import React from 'react';
import { Layout } from 'layout';
import {PageHeader, AboutUsModules, SimpleCards, SectionHeading, CallToAction} from 'components';

let header = "Meet the founder";
let subheader = "If you would like to join our efforts, contact me!";

const About = () => (
    
    <Layout tabHeader="About Page">
        <div className='colored-background'>
        <PageHeader headerType={'simple'} header={"About Us."}/>
        </div>
        <AboutUsModules/>
        <CallToAction title="If you want to stay updated on new upcoming features..." actionButtonTitle="The Newsletter" userActionButtonTitle="Contact Us"/>
        <div className='darker-background-wrapper'>
        <SectionHeading header={header} subHeading={subheader}/>
        <SimpleCards/>
        </div>
    </Layout>
);

export default About;