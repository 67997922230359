import React, { useState, useEffect } from 'react';
import { PageHeader, TrendingProjectsFeed, SectionHeading, CallToAction, Communities } from 'components';
import { Layout } from 'layout';
import pitchForm from 'images/post-pitch-screenshot.png';
import pitchFormMobile from 'images/post-pitch-screenshot.png';
import swipingVideo from 'images/swiping-hero-animation.mp4';
import swipingVideoCropped from 'images/swiping-hero-animation-cropped.mp4';
import networkBenefits from 'images/feature-benefits.png';
import Globe05 from "@untitled-ui/icons-react/build/cjs/Globe05";
import BezierCurve02 from "@untitled-ui/icons-react/build/cjs/BezierCurve02";
import UsersPlus from "@untitled-ui/icons-react/build/cjs/UsersPlus";

const EarlyLandingPage = () => {
    const [isSignInToggleOnPrompt, setIsSignInToggleOnPrompt] = useState(false);
    const [redirectURL, setRedirectURL] = useState('');
    const [isNow, setIsNow] = useState(true);

    useEffect(() => {
        const setLoginPrompt = () => {
            const params = new URLSearchParams(window.location.search);
            const promptLogin = params.get('prompt_login');
            
            if (promptLogin === 'true') {
                setIsSignInToggleOnPrompt(true);
                setRedirectURL(params.get('redirect'));
            }
        };

        setLoginPrompt();
    }, []);

    const handleToggle = () => {
        setIsNow(!isNow);
    };

    return (
        <Layout tabHeader="Home Page">
            <div className="main-page">
                <div className='colored-background header-section'>
                    <PageHeader 
                        displayDiscoveryBtn={'inline'} 
                        mainPhoto={swipingVideo}
                        // preHeader={"From side gigs to startups -"}
                        header={"Pitch here, find partners \n & collaborate together."}
                        // subHeader={"Swipe other personal pitches to find and meet local potential partners."}
                        subHeader={"Whether it's a side hustle, side gig, or side quest: \n post & swipe through pitches to meet local partners."}
                    />
                    {/* <Banner/> */}
                </div>
                <div className='pitch-simplification-wrapper'>
                    <SectionHeading 
                        header="1. Upload your video profile."
                        subHeading="Pitch yourself to the community in a video with 4 topics."
                    />
                    <picture>
                        <source srcSet={pitchFormMobile} media="(max-width: 900px)" />
                        <img src={pitchForm} alt="Pitch prompts"/>
                    </picture>
                </div>
                <div className="gray-container">
                    <div className='network-benefits-section'>
                        <SectionHeading 
                            header="2. Swipe through potential partners."
                            subHeading={"Match with talent from your local community."}
                        />
                        <div className='video-explanation'>
                            <div className='cropped-swiping-video'>
                                <video className='hero' alt="sidejawn-header-photo" autoPlay loop muted playsInline>
                                    <source src={swipingVideoCropped} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className='explanation'>
                                <div className='promptContainer'>
                                    <Globe05 className='icon'/>
                                    <h1 className='colorlib-heading'>Local talent.</h1>
                                </div>
                                <div className='promptContainer'>
                                    <BezierCurve02 className='icon'/>
                                    <h1>Multiple communities.</h1>
                                </div>
                                <div className='promptContainer'>
                                    <UsersPlus className='icon'/>
                                    <h1>Connect before & after events.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Communities />
                <div className='join-now-wrapper gray-container'>
                    <SectionHeading header="Join over 150+ sidejawn members."/>
                    <div className='skill-tags-section'>
                        <div className='skill-tags'>
                            <div>
                                Sales & Marketing
                            </div>
                            <div>
                                Software Developers
                            </div>
                            <div>
                                UI/UX Designers
                            </div>
                            <div>
                                Product Specialists
                            </div>
                            <div>
                                Advisors
                            </div>
                            <div>
                                Mentors
                            </div>
                            <div>
                                Creatives
                            </div>
                            <div>
                                Operations Managers
                            </div>
                            <div>
                                University Students
                            </div>
                        </div>
                    </div>
                    <CallToAction 
                        actionButtonTitle="Join them now" 
                        userActionButtonTitle="Give Us Feedback" 
                        imageDesc="sidejawn partners connecting through startup"
                    />
                </div>
                {/* <div className="gray-container">
                    <div className='join-now-wrapper'>
                        <SectionHeading subHeading="We'd love to enhance your networking events!"/>
                        <CallToAction 
                            userActionButtonTitle="Let's talk" 
                            imageDesc="sidejawn partners connecting through startup"
                        />
                    </div>
                </div> */}
            </div>
        </Layout>
    );
};

export default EarlyLandingPage;