import React, { useContext, useState } from 'react'
import './CallToAction.scss'
import { Button } from 'react-bootstrap'
import { FormModal, CreateAccountForm, ContactForm, SignInForm, ResetPasswordForm } from 'sub-components';
import AuthContext from 'app/AuthContext';
import { SectionHeading } from 'components';
import { getEnvConfig } from 'lib/utils';

const CallToAction = (props) => {
    const { authToken } = useContext(AuthContext);
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [isResetPwdToggleOn, setResetPwdToggleOn] = useState(false);
    const [isContactToggleOn, setIsContactToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
     // Get messaging info from the config files
     const messaging = getEnvConfig().messaging;

	const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const resetPwdClick = () => {
		setResetPwdToggleOn(!isResetPwdToggleOn);
		setActionCompleted(false);
	};

    const handleContactClick = () => {
        setIsContactToggleOn(!isContactToggleOn);
        setActionCompleted(false);
    }

    const resetPwdCompleted = () => {
		setActionCompleted(true);
	};

    const handleActionCompleted = () => {
        setActionCompleted(true);
        const id = setTimeout(() => {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
            setResetPwdToggleOn(false);
            setIsContactToggleOn(false);
        }, 1000);
        setTimeoutId(id);
    };

    return (
        <div className="colorlib-cta">
            {props.image && (
                <img src={props.image} alt={props.imageDesc} />
            )}
            <FormModal
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
                <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm resetPwdClick={resetPwdClick} handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
				isToggleOn={isResetPwdToggleOn}
				handleClick={resetPwdClick}
				onClose={() => clearTimeout(timeoutId)}
				actionCompleted={actionCompleted}
				title="Reset Password"
				successfulActionTitle="Reset Password Link Sent!"
				successfulActionMessage={
					<div>
						Please check your email for the reset password link.
					</div>
				}
				>
				<ResetPasswordForm resetPwdClick={resetPwdClick} onActionCompleted={resetPwdCompleted} />
			</FormModal>
            <FormModal 
                isToggleOn={isContactToggleOn} 
                handleClick={handleContactClick}
                actionCompleted={actionCompleted}
                title="Contact Us"
                successfulActionTitle="Message Sent"
                successfulActionMessage="Thanks for contacting us. We will respond via email.">
                <ContactForm onActionCompleted={handleActionCompleted} />
            </FormModal>
            <div className="call-to-action text-center">
                {props.title && (
                 <SectionHeading header={props.title} />   
                )}
                {props.actionButtonTitle != null ? (
                    <Button variant="primary" onClick={handleSignUpClick}>
                        {props.actionButtonTitle}
                    </Button>
                ) : 
                (
                    <Button variant="primary" onClick={handleContactClick}>
                        {props.userActionButtonTitle}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default CallToAction;
