import React, { Component } from 'react'
import hai from 'images/hai-profile.jpg'
import christian from 'images/christian-iosif-profile.jpg'
import brandon from 'images/brandon-tran-profile.jpeg'
import './SimpleCards.scss'
import 'layout/index.scss'
import LinkedInIcon from 'images/linkedin-icon.webp'

export default class SimpleCards extends Component {
	constructor(props) {
        super(props);
        this.state = {
            header: this.props.header,
            subHeader: this.props.subHeader,
            tallestModuleHeight: 0,
			christian: {
				christianId: "b1f55fa4-8158-4fbd-935c-e43b63ecf2d6",
				christianLinkedIn: "https://www.linkedin.com/in/christian-iosif-217015152/",
			}
		};
    }

    componentDidMount() {
        this.adjustModuleHeight();
        window.addEventListener("resize", this.adjustModuleHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.adjustModuleHeight);
    }

    adjustModuleHeight = () => {
        let tallestModuleHeight = 0;
        const modules = document.getElementsByClassName("staff-entry");
        for (let i = 0; i < modules.length; i++) {
            modules[i].style.height = "auto";
            if (modules[i].offsetHeight > tallestModuleHeight) {
                tallestModuleHeight = modules[i].offsetHeight;
            }
        }
        for (let i = 0; i < modules.length; i++) {
            modules[i].style.height = `${tallestModuleHeight}px`;
        }
        this.setState({ tallestModuleHeight });
    }

	handleProfileClick = (profileId) => {
		window.open('/profile/' + profileId);
	}
	
	handleSocialsClick	= (social) => {
		window.open(social);
	}

    render() {
        return (
            <div className="row" style={{paddingTop: '3%'}}>
				<div className="container">
					<div className="simple-card col-md-3 text-center animate-box">
						<div className="staff-entry semi-clickable" onClick={() => this.handleProfileClick(this.state.christian.christianId)}>
							<div className='staff-content-wrapper'>
								<a className="staff-img" style={{backgroundImage: `url(${christian})`}}></a>
								<div className="desc">
									<h3>Christian Iosif</h3>
									<span>Founder & CEO</span>
									<img color="#5D9898"  style={{height: '30px'}} src={LinkedInIcon} onClick={(e) => {e.stopPropagation(); this.handleSocialsClick(this.state.christian.christianLinkedIn)}}/>
									<div>Growing up in an immigrant household, I experienced firsthand the struggles of navigating new systems and building connections from scratch. It was my college mentor who paved my path to becoming a software engineer. Now, equipped with these skills, I'm on a mission to empower others to work on the goals they care about.</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        )
    }
}