import React, { useContext, useState, useRef, useEffect } from 'react'
import './NavBar.scss'
import { FormModal, Burger, CreateAccountForm, SignInForm, ProjectCardForm, ResetPasswordForm } from 'sub-components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import AuthContext from 'app/AuthContext';
import profileImage from 'images/blank-profile-picture.png'
import Module from 'sub-components/module/Module';
import projectIcon from 'images/project-icon.png';
import personIcon from 'images/person-icon.png';
import multiProjectsIcon from 'images/multi-projects-icon.png';
import { getProjCategoryOpts } from 'lib/project';
import { getEnvConfig, formatAvatarUrl } from 'lib/utils';

const NavBar = ({ isSignInToggleOnPrompt, redirectURL }) => {
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
	const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
	const [isResetPwdToggleOn, setResetPwdToggleOn] = useState(false);
	const [isCreateToggleOn, setIsCreateToggleOn] = useState(false);
	const [actionCompleted, setActionCompleted] = useState(false);
	const [timeoutId, setTimeoutId] = useState(null);
	const [actionCompletedType, setActionCompletedType] = useState(null);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
	
	const { authToken, userMetadata, logout} = useContext(AuthContext);
	const userId = userMetadata ? userMetadata.id : "";
	const previousAuthToken = useRef(authToken);
	const navigate = useNavigate();

	// Manages the profile access in the nav bar when a user is logged in
	const profileMenuRef = useRef();
	const profileCircleRef = useRef();
	const [profileMenuVisible, setProfileMenuVisible] = useState(false);
	const category = getProjCategoryOpts();

	useEffect(() => {
		if (isSignInToggleOnPrompt && isSignInToggleOnPrompt === true) {
			setIsSignInToggleOn(!isSignInToggleOn);
			setActionCompleted(false);
		}
	}, [isSignInToggleOnPrompt]);

	// Navigates the user to the home page when their token expires
	useEffect(() => {
		if (previousAuthToken.current && authToken === null) {
			navigate('/');
		}
		previousAuthToken.current = authToken;
	}, [authToken, navigate]);

	// Closes the profile menu when the user clicks outside of it
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	// Fixes some glitches with the profile and its menu
	useEffect(() => {
		const handleProfileCircleClick = () => {
			setProfileMenuVisible((prevState) => {
				const newState = !prevState;
				if (process.env.REACT_APP_ENVIRONMENT === "dev") {
					console.log("New profile menu state:", newState);
				}
				return newState;
			});
		};
	  
		if (profileCircleRef.current) {
		  	profileCircleRef.current.addEventListener("click", handleProfileCircleClick);
		}
	  
		return () => {
			if (profileCircleRef.current) {
				profileCircleRef.current.removeEventListener("click", handleProfileCircleClick);
			}
		};
	}, [authToken]);

	const location = useLocation();

  useEffect(() => {
    function handleScroll() {
      const topMenu = document.querySelector('.top-menu');
      if (window.scrollY > 0) {
        topMenu.style.backgroundColor = 'rgba(41, 49, 60, 0.9)'; // Change to your desired color
      } else {
        topMenu.style.backgroundColor = 'transparent';
      }
    }

    function checkPathAndAddScrollListener() {
      const currentPath = location.pathname;
      if (currentPath === '/') {
        window.addEventListener('scroll', handleScroll);
        // Call handleScroll initially to set the correct background on page load
        handleScroll();
      }
	  else {
        window.removeEventListener('scroll', handleScroll);
        // Optionally, reset the background color if navigating away from EarlyLandingPage
        const topMenu = document.querySelector('.top-menu');
        if (topMenu) {
		  if (isSmallScreen) {
			topMenu.style.backgroundColor = 'transparent'; // Set to default solid color or any desired color
		  }
		  else {
			topMenu.style.backgroundColor = 'rgba(41, 49, 60, 0.9)'; // Set to default solid color or any desired color
		  }
        }
      }
    }

    checkPathAndAddScrollListener();

    // Cleanup scroll event listener on component unmount or path change
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);
	
	
	const handleActionCompleted = (actionType) => {
		if (actionType) { setActionCompletedType(actionType); }
		setActionCompleted(true);
		// This is for timing the prompt message, then auto closing it after 1000ms/1 sec
        // const id = setTimeout(() => {
        //     setIsSignUpToggleOn(false);
        // }, 1000);
        // setTimeoutId(id);
	};

	const handleSignInCompleted = () => {
		setActionCompleted(true);
		navigate('/discovery');
	}

	const resetPwdCompleted = () => {
		setActionCompleted(true);
	}

	const handleCreateCompleted = () => {
		setActionCompleted(true);
		const id = setTimeout(() => {
			setIsCreateToggleOn(false);
			window.location.reload();
		}, 1000);
		setTimeoutId(id);
		if (window.location.pathname.includes("/profile") ) { 
			navigate('/profile/' + userId);
		} else {
			navigate(window.location.pathname);
		}
	}

	const handleProfileClick = () => {
		if(isSignInToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		if(isSignUpToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		navigate(`/profile/${userId}?is_profile_view=true`);
		setProfileMenuVisible(false);
	};

	const handleAccountPrefClick = () => {
		if(isSignInToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		if(isSignUpToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		navigate(`/profile/${userId}/settings`);
		setProfileMenuVisible(false);
	};

	const handleCreateClick = () => {
		if(isSignInToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		if(isSignUpToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		// setIsCreateToggleOn(!isCreateToggleOn);
		setActionCompleted(false);
		setProfileMenuVisible(false);
		navigate(`/profile/${userId}/video/edit`);
	}

	const handleSignUpClick = () => {
		setIsSignUpToggleOn(!isSignUpToggleOn);
		setActionCompletedType(null);
		setActionCompleted(false);
	};

	const handleSignInClick = () => {
		setIsSignInToggleOn(!isSignInToggleOn);
		setActionCompleted(false);
	};

	const resetPwdClick = () => {
		setResetPwdToggleOn(!isResetPwdToggleOn);
		setActionCompleted(false);
	};

	const handleLogOutClick = () => {
		setProfileMenuVisible(false);
		navigate('/');
		logout();
	}

	const handleClickOutside = (event) => {
		if (
			profileMenuRef.current &&
			!profileMenuRef.current.contains(event.target) &&
			!profileCircleRef.current.contains(event.target)
		) {
			setProfileMenuVisible(false);
		}
	};

	const handleCategoryClick = (category) => {
		navigate(`/discovery?category=${category}`);
	}

	const handleCommunityClick = (comm) => {
		navigate(`${comm}`);
	}

	const handleBrowseClick = () => {
		if(isSignInToggleOn || isSignUpToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		setProfileMenuVisible(false);
		navigate(`/discovery`);
	}

	const handleLogoClick = () => {
		authToken ? navigate(`/discovery`) : navigate('/');
	}

	/**
	 * The function `handleSignedIn` checks if there is an authentication token and a redirect URL, and if
	 * so, it navigates to the specified URL; otherwise, it clears a timeout.
	 */
	const handleSignedIn = () => {
		if ((authToken) && (redirectURL && redirectURL !== '')) {
			const url = new URL(redirectURL);
			navigate(url.pathname + url.search);
		} else {
			clearTimeout(timeoutId);
		}
	}

	// Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

	return (
		<nav className="colorlib-nav" role="navigation" style={{flex: 1, flexWrap: 'wrap'}}>
			<Burger href="javascript:void(0)" handleSignInClick={handleSignInClick} handleSignUpClick={handleSignUpClick} handleLogOutClick={handleLogOutClick} handleProfileClick={handleProfileClick} handleCreateClick={handleCreateClick} resetPwdClick={resetPwdClick}/>
			<FormModal
				isToggleOn={isSignUpToggleOn}
				handleClick={handleSignUpClick}
				onClose={() => clearTimeout(timeoutId)}
				actionCompleted={actionCompleted}
				title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
				successfulActionMessage={
                    actionCompletedType === "signup_native_user" ? (
                        <div>{messaging.sign_up.ui.success.msg}</div>
                    ) : (
                        actionCompletedType === "signup_user" ? (
							<h3>Welcome to sidejawn!</h3>
                            // <div>
                            //     <h3>Here are some things you can currently do:</h3>
                            //     <div className="boxes-wrapper">
							// 		<Module onClick={handleBrowseClick} image={multiProjectsIcon} imageDesc="sidejawn projects icon" header="Browse Startups" content="Browse startups and apply to ones that interest you." className="semi-clickable" />
							// 		<Module onClick={handleCreateClick} image={projectIcon} imageDesc="sidejawn project icon" header="Post Your Startup" content="Organize your pitch to convince others to join your startup." className="semi-clickable" />
							// 		<Module onClick={handleProfileClick} image={personIcon} imageDesc="sidejawn green silhouette person icon" header="View Your Profile" content="Convince people why they should work with you." className="semi-clickable"/>
							// 	</div>
                            //     <div className="horizontal-divider"></div>
                            // </div>
                        ) : null // Added null to handle the case where actionCompletedType doesn't match either condition
                    )
                }>
			<CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
			</FormModal>
			<FormModal
				isToggleOn={isSignInToggleOn} 
				handleClick={handleSignInClick}
				onClose={handleSignedIn}
				actionCompleted={actionCompleted}
				title={messaging.sign_in.ui.title}
				successfulActionTitle={messaging.sign_in.ui.success.title}
				successfulActionMessage={
					<h3>Welcome back!</h3>
					// <div>
					// 	<h3>Here are some things you can currently do:</h3>
					// 	<div className="boxes-wrapper">
					// 		<Module onClick={handleBrowseClick} image={multiProjectsIcon} imageDesc="sidejawn projects icon" header="Browse Startups" content="Browse startups and apply to ones that interest you." className="semi-clickable" />
					// 		<Module onClick={handleCreateClick} image={projectIcon} imageDesc="sidejawn project icon" header="Post Your Startup" content="Organize your pitch to convince others to join your startup." className="semi-clickable" />
					// 		<Module onClick={handleProfileClick} image={personIcon} imageDesc="sidejawn green silhouette person icon" header="View Your Profile" content="Convince people why they should work with you." className="semi-clickable"/>
					// 	</div>
					// </div>
				}>
				<SignInForm resetPwdClick={resetPwdClick} handleSignUpClick={handleSignUpClick} onActionCompleted={handleSignInCompleted} />
			</FormModal>
			<FormModal
				isToggleOn={isResetPwdToggleOn}
				handleClick={resetPwdClick}
				onClose={() => clearTimeout(timeoutId)}
				actionCompleted={actionCompleted}
				title="Reset Password"
				successfulActionTitle="Reset Password Link Sent!"
				successfulActionMessage={
					<div>
						Please check your email for the reset password link.
					</div>
				}
				>
				<ResetPasswordForm onActionCompleted={resetPwdCompleted} />
			</FormModal>
			{/* <FormModal
				isToggleOn={isCreateToggleOn} 
				handleClick={handleCreateClick}
				actionCompleted={actionCompleted}
				onClose={() => clearTimeout(timeoutId)}
				modalType="project-card-modal"
				title="Post Startup"
				successfulActionTitle="Congratulations!"
				successfulActionMessage="You can access your post on your profile.">
				<ProjectCardForm
					handleActionCompleted={handleCreateCompleted}
					userMetadata={userMetadata}
					profileImage={userMetadata?.user_avatar ? formatAvatarUrl(userMetadata.user_avatar.url, userMetadata.user_avatar.updated_ts) : profileImage}
					editPostMode={false}
				/>
			</FormModal> */}
			<div className="top-menu">
				<div className="left-nav-buttons col-md-4 text-left">
					<div className="main-logo" onClick={handleLogoClick}>
						sidejawn 
						<div className='sub-tag'>
							beta
						</div>
					</div>
						<ul className='disappear-on-resize'>
							{/* <li><Link to="/about">About</Link></li> */}
							{/* <li><Link to="/resources">Resources</Link></li> */}
							{/* <li className="has-dropdown">
								<a style={{cursor:'pointer'}} onClick={() => handleBrowseClick()}>Startups </a>
								<ul id="dropdown" className='organized'>
									<li><Link to="/discovery">All Startups</Link></li>
									{category.map(item => (
										<li key={item.value}>
											<a style={{cursor:'pointer'}} onClick={() => handleCategoryClick(item.value)}>{item.label}</a>
										</li>
									))}
								</ul>
							</li> */}
							{/* <li className="has-dropdown">
								<a href="#">Communities </a>
								<ul id="dropdown">
									<li><a style={{cursor:'pointer'}} onClick={() => handleCommunityClick('/venture-cafe')}>Venture Cafe</a></li>
									<li><a style={{cursor:'pointer'}} onClick={() => handleCommunityClick('/founder-institute')}>PSL & Founder Institute</a></li>
								</ul>
							</li> */}
						</ul>
					</div>
					{authToken ? (
						<div className='profile-navbar-group'>
							<Button className='disappear-on-resize' onClick={handleCreateClick}>Post Pitch</Button>
							<div className="profile-circle disappear-on-resize" ref={profileCircleRef} style={{backgroundImage: `url(${userMetadata?.user_avatar?.url ? formatAvatarUrl(userMetadata.user_avatar.url, userMetadata.user_avatar.updated_ts): profileImage})`}} >
							</div>
							<div className={`profile-menu ${profileMenuVisible ? 'visible fadeInDownMenu animated-fast' : ''}`} ref={profileMenuRef}>
								{userMetadata && userMetadata.display_name && (
									<div className="profile-name">{userMetadata.display_name}
									<div className="divider"></div>
									</div>
								)}
								<ul>
								<li><a style={{cursor:'pointer'}} onClick={handleProfileClick}>Profile</a></li>
								<li><a style={{cursor:'pointer'}} onClick={handleAccountPrefClick}>Account Settings</a></li>
								<li><a style={{cursor:'pointer'}} onClick={handleLogOutClick}>Log Out</a></li>
								</ul>
							</div>
						</div>
					) : (
					<div className="right-nav-buttons text-right disappear-on-resize" >
						<ul>
							<li>
								<Button variant="secondary" style={{marginRight: "20px"}}  onClick={handleSignInClick} centered="true">
									Log in
								</Button></li>
							<li>
								<Button variant="primary" onClick={handleSignUpClick} centered="true">
									Join us
								</Button>
							</li>
						</ul>
					</div>
				)}
			</div>
		</nav>
	)
}

export default NavBar;