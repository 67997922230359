import React, { useState } from 'react';
import { Button } from 'react-bootstrap'
import {ContactForm} from 'sub-components'
import { Link } from 'react-router-dom';
import {FormModal} from 'sub-components'
import '../footer/Footer.scss'
import LinkedInIcon from 'images/linkedin-icon.webp'
import InstagramIcon from 'images/instagram-icon.png'

const Footer = () => {
    const [isToggleOn, setIsToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);

    const handleActionCompleted = () => {
        setActionCompleted(true);
        setTimeout(() => {
            setIsToggleOn(false);
        }, 1000);
    }

    const handleClick = () => {
        setIsToggleOn(!isToggleOn);
        setActionCompleted(false);
    }

    return (
        <footer id="colorlib-footer">
            <FormModal 
                isToggleOn={isToggleOn} 
                handleClick={handleClick}
                actionCompleted={actionCompleted}
                title="Contact Us"
                successfulActionTitle="Message Sent"
                successfulActionMessage="Thanks for contacting us. We will respond via email.">
                <ContactForm onActionCompleted={handleActionCompleted} />
            </FormModal>
            <div className="container">
                <div className="row row-pb-md footer-columns">
                    <div className='column-1'>
                        <div className="colorlib-widget">
                            <h4>About sidejawn</h4>
                            <p className="about-paragraph">We believe that people want to work on something important to them, and that they don't want to do it alone. Our community platform aims to improve how people meet potential collaborators at local events (and beyond) to bring their side gigs, hustles, and quests to life.</p>
                            <Button variant="primary" onClick={handleClick} centered="true">
                                Contact Us
                            </Button>
                        </div>
                    </div>
                    <div className="column-2">
                        {/* <div className="colorlib-widget">
                            <h4>Navigation</h4>
                            <ul className="colorlib-footer-links">
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/resources">Resources</Link></li>
                                <li><Link to="/discovery">Startups</Link></li>
                                <li><Link to="/venture-cafe">Communities</Link></li>
                            </ul>
                        </div> */}
                        <div className="colorlib-widget" id="footer-contact">
                            <h4>Contact Info</h4>
                            <ul className="colorlib-footer-links">
                                <li>Connect with the founder below</li>
                                <li><img style={{height: '20px', alignItems: 'center'}} src={LinkedInIcon}/> <Link to="https://www.linkedin.com/in/christian-iosif-217015152/">Christian Iosif</Link></li>
                                <li>Follow our socials</li>
                                <li><img style={{height: '20px', alignItems: 'center'}} src={LinkedInIcon}/> <Link to="https://www.linkedin.com/company/sidejawn/">LinkedIn</Link></li>
                                <li><img style={{height: '20px', alignItems: 'center'}} src={InstagramIcon}/> <Link to="https://www.instagram.com/sidejawn.io/">Instagram</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
