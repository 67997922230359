import React, {useState, useRef, useEffect, useContext} from 'react'
import {SlideDown} from 'react-slidedown'
import Icon from "icons/icon"
import { Link, useNavigate } from 'react-router-dom';
import './Burger.scss'
import 'react-slidedown/lib/slidedown.css'
import AuthContext from 'app/AuthContext'
import profileImage from 'images/blank-profile-picture.png'
import { getProjCategoryOpts } from 'lib/project';
import { formatAvatarUrl } from 'lib/utils';
import { getDefaultUserCommunities } from 'lib/user';
import {FormModal, ContactForm} from 'sub-components'
import Home02 from "@untitled-ui/icons-react/build/cjs/Home02";
import Globe05 from "@untitled-ui/icons-react/build/cjs/Globe05";
import CameraPlus from "@untitled-ui/icons-react/build/cjs/CameraPlus";
import LogOut04 from "@untitled-ui/icons-react/build/cjs/LogOut04";
import Mail01 from "@untitled-ui/icons-react/build/cjs/Mail01";
import InfoCircle from "@untitled-ui/icons-react/build/cjs/InfoCircle";
import LogIn04 from "@untitled-ui/icons-react/build/cjs/LogIn04";
import ArrowCircleBrokenUpRight from "@untitled-ui/icons-react/build/cjs/ArrowCircleBrokenUpRight";

const Burger = ({ handleSignUpClick, handleSignInClick, handleLogOutClick, handleProfileClick, handleCreateClick }) => {
	const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);
	const [isCategoriesClosed, setIsCategoriesClosed] = useState(true);
	const [isCommunitiesClosed, setIsCommunitiesClosed] = useState(true);
	const [menuClass, setMenuClass] = useState(
		'js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white'
	);
	const navigate = useNavigate();
	const category = getProjCategoryOpts();
	const defaultCommunities = getDefaultUserCommunities();

	const { authToken, userMetadata} = useContext(AuthContext);
	const wrapperRef = useRef();

	// Contact form items
	const [isContactFormToggleOn, setIsContactFormToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			if (isMenuDisplayed) {
				toggleBurger();
			}
		}
	};

	const toggleBurger = () => {
		if (isMenuDisplayed) {
			setMenuClass('js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white');
			setIsMenuDisplayed(false);
			setIsCategoriesClosed(true);
			document.body.classList.remove('fixed-body');
		} else {
			setMenuClass('active js-colorlib-nav-toggle colorlib-nav-toggle colorlib-nav-white');
			setIsMenuDisplayed(true);
			document.body.classList.add('fixed-body');
		}
	};

	const toggleCategories = () => {
		setIsCategoriesClosed(!isCategoriesClosed);
	};

	const toggleCommunities = () => {
		setIsCommunitiesClosed(!isCommunitiesClosed);
	};

	const handleSignUp = () => {
		toggleBurger();
		handleSignUpClick();
	};

	const handleSignIn = () => {
		toggleBurger();
		handleSignInClick();
	};

	const handleLogOut = () => {
		toggleBurger();
		handleLogOutClick();
	}

	const handleProfile = () => {
		toggleBurger();
		handleProfileClick();
	}

	const handleCreate = () => {
		toggleBurger();
		handleCreateClick();
	}

	const handleCategoryClick = (category) => {
		toggleBurger();
		if (category.includes('all')) {
			navigate(`/discovery`);
		} else {
			navigate(`/discovery?category=${category}`);
		}
	}

	const handleCommunityClick = (comm) => {
		toggleBurger();
		navigate(`${comm}`);
	}

	const handleActionCompleted = () => {
        setActionCompleted(true);
        setTimeout(() => {
            setIsContactFormToggleOn(false);
        }, 1000);
    }

    const handleContactClick = () => {
        setIsContactFormToggleOn(!isContactFormToggleOn);
        setActionCompleted(false);
    }

	return (
		<div ref={wrapperRef} className={isMenuDisplayed ? "overflow offcanvas" : ""}>
			<FormModal 
                isToggleOn={isContactFormToggleOn} 
                handleClick={handleContactClick}
                actionCompleted={actionCompleted}
                title="Contact Us"
                successfulActionTitle="Message Sent"
                successfulActionMessage="Thanks for contacting us. We will respond via email.">
                <ContactForm onActionCompleted={handleActionCompleted} />
            </FormModal>
			<a onClick= {toggleBurger} className={menuClass}><i></i></a>
			<div id="colorlib-offcanvas" className={isMenuDisplayed ? "" : "closed"}>
				<ul>
					{ authToken && 
						<>
							<li><Link onClick= {toggleBurger} to="/discovery"><Home02/> Explore</Link></li>
							<li className="" >
								<a onClick= {toggleCommunities}><Globe05/> Communities</a>
								<SlideDown className={'pure-menu pure-menu-scrollable dropdown-slidedown'} closed={isCommunitiesClosed}>
									<ul>
										{defaultCommunities.map((community, index) => (
											<li key={index}>
												<a
													style={{ cursor: 'pointer', fontSize: '14px'}}
													onClick={() => handleCommunityClick(`discovery/community?name=${encodeURIComponent(community.value)}`)}
												>
													{community.label}
												</a>
											</li>
										))}
									</ul>
								</SlideDown>
							</li>
						</>
					}
					<li><Link onClick= {toggleBurger} to="/about"><InfoCircle/> About</Link></li>
					<li><Link onClick= {handleContactClick} ><Mail01/> Contact Us</Link></li>
					{/* <li><Link onClick= {toggleBurger} to="/resources">Resources</Link></li> */}
					{/* <li className="" > */}
					{/* <a style={{cursor:'pointer'}} onClick= {toggleCategories}>Startups <Icon color="white" className="dropdown-icon" size={10} icon="circle-down"/></a>
						<SlideDown className={'pure-menu pure-menu-scrollable dropdown-slidedown'} closed={isCategoriesClosed}>
							<ul>
								<li><a style={{cursor:'pointer'}} onClick={() => handleCategoryClick('all')}>All</a></li>
								{category.map(item => (
									<li key={item.value}>
										<a style={{cursor:'pointer'}} onClick={() => handleCategoryClick(item.value)}>{item.label}</a>
									</li>
								))}
							</ul>
						</SlideDown>
					</li> */}
				</ul>
			<hr className="menu-division"/>
			{authToken ? (
					<ul className="actions">
						<li className="profile-container-mobile" onClick={handleProfile}>
							<div className="profile-circle-mobile" style={{backgroundImage: `url(${userMetadata?.user_avatar ? formatAvatarUrl(userMetadata.user_avatar.url, userMetadata.user_avatar.updated_ts) : profileImage})`}}>
							</div>
							{userMetadata && userMetadata.display_name && (
								<div className="user-name">{userMetadata.display_name}</div>
							)}
						</li>
						<li><a style={{cursor:'pointer'}} onClick={handleCreate}><CameraPlus/> Post pitch</a></li>
						<li><a style={{cursor:'pointer'}} onClick={handleLogOut}><LogOut04/> Log out</a></li>
					</ul>
					) : (
					<ul className="actions">
						<li><a style={{cursor:'pointer'}} onClick={handleSignUp}><ArrowCircleBrokenUpRight/> Sign up</a></li>
						<li><a style={{cursor:'pointer'}} onClick={handleSignIn}><LogIn04/> Log in</a></li>
					</ul>
				)}
			</div>
		</div>
	)
}
export default Burger;