import React, { useEffect, useContext, useState, useRef } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {SlideDown} from 'react-slidedown'
import { FormModal, CreateAccountForm, SignInForm, PersonalMessageForm } from 'sub-components';
import { UserProfileVideoOverlayed } from 'components';
import AuthContext from 'app/AuthContext';
import { getEnvConfig } from 'lib/utils';
import classes from './DiscoveryPage.module.scss';
import { getUserRecommended, getDefaultUserCommunities, updateWatchedVideos } from 'lib/user';
import Share06 from "@untitled-ui/icons-react/build/cjs/Share06";
import Mail01 from "@untitled-ui/icons-react/build/cjs/Mail01";
import Home02 from "@untitled-ui/icons-react/build/cjs/Home02";
import Globe05 from "@untitled-ui/icons-react/build/cjs/Globe05";
import { v4 as uuidv4 } from 'uuid';

const DiscoveryPage = () => {
    const { authToken, userMetadata } = useContext(AuthContext);
    const navigate = useNavigate();
    const loadingTimeoutRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [isApplicationToggleOn, setApplicationToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [userVideos, setUserVideos] = useState([]);
    const [direction, setDirection] = useState(1); // 1 for down, -1 for up
    const [notificationVisible, setNotificationVisible] = useState(false); // State for notification
    const containerRef = useRef(null);
    const videoRefs = useRef([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [playingStates, setPlayingStates] = useState(() =>
        userVideos.map((_, index) => index === 0 ? false : false)
    );

    // Manages which videos were watched
    const [watchedVideos, setWatchedVideos] = useState(new Set());
    const watchedVideosRef = useRef(new Set());

    // Tab/Filter management
    const { tab } = useParams(); // "explore", "communities", etc.
    const [searchParams] = useSearchParams();
    const communityName = searchParams.get('name'); // For communities dropdown
    const [isCommunitiesClosed, setIsCommunitiesClosed] = useState(true);
    const defaultCommunities = getDefaultUserCommunities();

    const toggleCommunities = () => {
		setIsCommunitiesClosed(!isCommunitiesClosed);
	};

    // useEffect(() => {
    //     if (!authToken) {
    //         if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("No auth token found. Please login."); }
    //         // handleSignUpClick();
    //         handleSignInClick();
    //     }
    // }, [authToken]); // useEffect will run whenever authToken changes

    const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

    const handleActionCompleted = () => {
        setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
    };

    const checkIfLoggedIn = () => {
        if (authToken) { 
            clearTimeout(timeoutId); 
        } else {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
        }
    };

    // Snapping logic
    const handleScroll = () => {
        if (!containerRef.current) return;
    
        const container = containerRef.current;
        const videoHeight = container.clientHeight;
        const scrollTop = container.scrollTop;
    
        // Calculate the index of the closest video
        const nextIndex = Math.round(scrollTop / videoHeight);
    
        // If index changes, update and snap
        if (nextIndex !== currentVideoIndex) {
          setCurrentVideoIndex(nextIndex);
          container.scrollTo({
            top: nextIndex * videoHeight,
            behavior: "smooth",
          });
        }
    };

    // Helper to add a video to the watched list
    const addWatchedVideo = (videoId) => {
        if (!watchedVideosRef.current.has(videoId)) {
            watchedVideosRef.current.add(videoId); // Add to ref
            setWatchedVideos((prev) => {
                const updated = new Set(prev);
                updated.add(videoId);
                console.log(updated)
                return updated;
            });
        }
    };

    useEffect(() => {
        const container = containerRef.current;
    
        // Attach scroll event listener
        if (container) {
          container.addEventListener("scroll", handleScroll, { passive: false });
        }
    
        // Cleanup on component unmount
        return () => {
          if (container) {
            container.removeEventListener("scroll", handleScroll);
          }
        };
    }, [currentVideoIndex]);

    useEffect(() => {
        // Ensure the container snaps to the current video index on mount or update
        if (containerRef.current) {
          const videoHeight = containerRef.current.clientHeight;
          containerRef.current.scrollTo({
            top: currentVideoIndex * videoHeight,
            behavior: "instant",
          });
        }
      }, [currentVideoIndex]);

    // Share video function copies the profile link to the clipboard
    const shareVideo = () => {
        const profileLink = process.env.REACT_APP_ENVIRONMENT === "dev" ? "http://localhost:3000" : "https://www.sidejawn.io";
        if (userVideos[currentVideoIndex]?.profileMetadata?.userId){
            profileLink = profileLink + '/profile/' + userVideos[currentVideoIndex]?.profileMetadata?.userId;
        }
    
        // Check if navigator.clipboard is available
        if (navigator.clipboard) {
            navigator.clipboard.writeText(profileLink).then(() => {
                setNotificationVisible(true); // Show notification
                setTimeout(() => {
                    setNotificationVisible(false); // Hide notification after 2 seconds
                }, 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback method
            const textarea = document.createElement('textarea');
            textarea.value = profileLink;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy'); // Attempt to copy
                setNotificationVisible(true); // Show notification
                setTimeout(() => {
                    setNotificationVisible(false); // Hide notification after 2 seconds
                }, 2000);
            } catch (err) {
                console.error('Fallback: Unable to copy: ', err);
            }
            document.body.removeChild(textarea); // Clean up
        }
    };

    const messagePerson = () => {
        if (authToken) {
            openApplicationForm();
        } else {
            handleSignInClick();
        }
    };

    const openApplicationForm = () => {
        setApplicationToggleOn(!isApplicationToggleOn);
        setActionCompleted(false);
    };

    const handleApplyCompleted = () => {
		setActionCompleted(true);
        // setDisableApplyButton(true);
        // setApplyButtonVerbage("Applied");
        // setShowCheckMark(true);
        // props.setIsApplied(true);
	};

    const loadUserVideos = async () => {
        // TODO: Create better way to track a guest user
        const filters = {
            filters: {
                ...(communityName ? { community: communityName } : {}),
                ...(tab === 'explore' && { 
                    watched: false, 
                    emailed: false 
                }),
                // Explore batches are limited to 3 people. Communities have less of a limit for event purposes.
                maxBatch: tab == 'community' ? 10 : 5,
                // Explore batches are released when 5 people are available.
                minBatch: tab == 'community' ? 0 : 0,
            }
        };
        
        const { isSuccess: loadVideosSuccess, resp: recommendedVideosResp, err: loadVideosError } =  await getUserRecommended(userMetadata?.id ? userMetadata.id : 'guest-id', 'videos', filters, authToken ? authToken : 'guest-auth');
        if (loadVideosSuccess) {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Video Recs Loading || Success", recommendedVideosResp); }
            const extendedVideos = [
                ...(recommendedVideosResp.results ? recommendedVideosResp.results : []),
                { id: uuidv4(), isPlaceholder: true, profileMetadata: {} }, // Add a placeholder at the end
            ];
            setUserVideos(extendedVideos);
        }
        else {
            if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Error loading user video recs", loadVideosError); }
        }
    };

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
   `useEffect` hook is used to load projects and show a loading spinner if the projects haven't
   loaded after 2 seconds. */
   useEffect(() => {
        // Only shows the loading spinner if the projects haven't loaded after 2 seconds
        loadingTimeoutRef.current = setTimeout(() => {
            setIsLoading(true);
        }, 2000);
        loadUserVideos(startIndex);
    }, [userMetadata, communityName]);

    useEffect(() => {
        // When user videos data changes, set loading to false
        clearTimeout(loadingTimeoutRef.current);
        setIsLoading(false);
    }, [userVideos]);

    // Leverages the observer to know when to automatically play videos that come into view, and keeps track of videos that are watched
    useEffect(() => {
        const observerOptions = {
            root: containerRef.current,
            rootMargin: '0px',
            threshold: 0.9,
        };
    
        const observer = new IntersectionObserver((entries) => {
            setPlayingStates((prevStates) => {
                const newStates = [...prevStates];
                let isPlaceholderVisible = false;
        
                entries.forEach((entry) => {
                    const index = parseInt(entry.target.getAttribute('data-index'), 10);
                    const video = userVideos[index];
        
                    if (video?.isPlaceholder && entry.isIntersecting) {
                        isPlaceholderVisible = true;
                    } else if (video && !video?.isPlaceholder) {
                        if (entry.isIntersecting) {
                            if (!watchedVideosRef.current.has(video.id)) {
                                addWatchedVideo(video.id); // Add to watched videos
                                updateWatchedVideos(userMetadata.id, [video.id], authToken); // Trigger API call
                            }
                        }
                        newStates[index] = entry.isIntersecting;
                    }
                });
        
                if (isPlaceholderVisible) {
                    for (let i = 0; i < newStates.length; i++) {
                        newStates[i] = false;
                    }
                }
        
                return newStates;
            });
        }, observerOptions);
    
        // Observe all video wrappers
        videoRefs.current.forEach((videoWrapper, index) => {
            if (userVideos[index]?.isPlaceholder) return; // Skip placeholder
            if (videoWrapper) observer.observe(videoWrapper);
        });
    
        return () => {
            videoRefs.current.forEach((videoWrapper) => {
                if (videoWrapper) observer.unobserve(videoWrapper);
            });
        };
    }, [userVideos]);

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    const handleFilterChange = (filter) => {
        navigate(`/discovery/${filter}`);
    };

    return (
        <div className={classes.discoveryContainer}>
            <FormModal
                backdrop='static'
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={messaging.sign_up.ui.success.msg}>
			    <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                backdrop='static'
                isToggleOn={isSignInToggleOn}
                handleClick={handleSignInClick}
                onClose={checkIfLoggedIn}
                actionCompleted={actionCompleted}
                title={messaging.sign_in.ui.title}>
			    <SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            <FormModal
                isToggleOn={isApplicationToggleOn}
                handleClick={openApplicationForm}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title="Send a Message"
                successfulActionTitle="Message Sent!"
                successfulActionMessage={"Check your email for next steps, and keep exploring! \n (Double check your junk mail just in case.)"}>
                <PersonalMessageForm
                    onActionCompleted={handleApplyCompleted}
                    receiverId={userVideos[currentVideoIndex]?.profileMetadata?.userId}
                />
            </FormModal>
            <div className={classes.headerSection}>
                <div className={classes.sideMenu}>
                    <ul>
                        {/* TODO: Add when we add more filters */}
                        <li><a onClick={() => handleFilterChange('explore')} ><Home02/> Explore</a></li>
                        <li>
                            <a onClick= {toggleCommunities}><Globe05/> Communities</a>
                            <SlideDown className={'pure-menu pure-menu-scrollable dropdown-slidedown'} closed={isCommunitiesClosed}>
                                <ul>
                                    {defaultCommunities.map((community, index) => (
                                        <li key={index}>
                                            <a
                                                style={{ cursor: 'pointer', fontSize: '14px'}}
                                                onClick={() => handleFilterChange(`community?name=${community.value}`)}
                                            >
                                                {community.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </SlideDown>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={classes.mainContent}>
                {/* Loading spinner or no video message */}
                {userVideos.length === 0 ? (
                    <div className={classes.noVideos}>We're working hard to bring you more pitches. <br/> We'll email you when your next batch is available.</div>
                ) : (
                    <div className={classes.interactionContainer}>
                        <div
                            ref={containerRef}
                            className={classes.videoContainer}
                            >
                            {userVideos?.map((video, index) => (
                                <div
                                key={video.id}
                                className={classes.videoWrapper}
                                ref={(el) => (videoRefs.current[index] = el)}
                                data-index={index}
                                >
                                    {!video.isPlaceholder ? (
                                        <UserProfileVideoOverlayed
                                        pitchVideo={video.userVideo}
                                        pitchMetadata={video.profileMetadata}
                                        isPlaying={playingStates[index]} // Pass playing state
                                        />
                                    ) : (
                                        <div className={classes.noVideos}>
                                            <h3 className={classes.communityName}>
                                                {communityName ? `${communityName}!` : `${tab.charAt(0).toUpperCase()}${tab.slice(1)}`}
                                            </h3>
                                            We're working hard to bring you more pitches.<br />
                                            We'll email you when your next batch is available.
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className={classes.videoNavButtons}>
                            <button onClick={messagePerson}><Mail01/></button>
                            <button onClick={shareVideo}><Share06/></button>
                        </div>
                    </div>
                )}
            </div>
            {/* Notification for link copied */}
            {notificationVisible && (
                <div className={classes.notification}>
                    Link copied to clipboard!
                </div>
            )}
        </div>
    );
}

export default DiscoveryPage;
