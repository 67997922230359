import React, { useContext, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { FormModal, CreateAccountForm, ProjectCardForm } from 'sub-components';
import './PageHeader.scss'
import AuthContext from 'app/AuthContext';
import Module from 'sub-components/module/Module';
import profileImage from 'images/blank-profile-picture.png'
import projectIcon from 'images/project-icon.png';
import profileIcon from 'images/profile-icon.png';
import multiProjectsIcon from 'images/multi-projects-icon.png';
import { getEnvConfig, formatAvatarUrl } from 'lib/utils';

const PageHeader = (props) => {
    const { authToken, userMetadata} = useContext(AuthContext);
    const [header, setHeader] = useState(props.header);
    const [subHeader, setSubHeader] = useState(props.subHeader);
    const [preHeader, setPreHeader] = useState(props.preHeader);
    const [displayLearnMoreBtn, setDisplayLearnMoreBtn] = useState(props.displayLearnMoreBtn || 'none');
    const [displayGoHomeBtn, setDisplayGoHomeBtn] = useState(props.displayGoHomeBtn || 'none');
    const [displayDiscoveryBtn, setDisplayDiscoveryBtn] = useState(props.displayDiscoveryBtn || 'none');
    const [isToggleOn, setIsToggleOn] = useState(false);
	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [actionCompletedType, setActionCompletedType] = useState(null);

    const [profileMenuVisible, setProfileMenuVisible] = useState(false);
    const [isCreateToggleOn, setIsCreateToggleOn] = useState(false);
    const userId = userMetadata ? userMetadata.id : "";
    const navigate = useNavigate();

    const handleClick = () => {
        setIsToggleOn(!isToggleOn);
    }

    const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompletedType(null);
        setActionCompleted(false);
    };

    const handleActionCompleted = (actionType) => {
        if (actionType) { setActionCompletedType(actionType); }
        setActionCompleted(true);
        setIsSignUpToggleOn(false);
        // This is for timing the prompt message, then auto closing it after 1000ms/1 sec
        // const id = setTimeout(() => {
        //     setIsSignUpToggleOn(false);
        // }, 1000);
        // setTimeoutId(id);
    };

    const handleBrowseClick = () => {
		if(isSignInToggleOn || isSignUpToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		setProfileMenuVisible(false);
		navigate(`/discovery`);
	}

    // const handleCreateClick = () => {
	// 	if (isSignInToggleOn) {
	// 		setIsSignInToggleOn(false);
	// 		setIsSignUpToggleOn(false);
	// 	}
	// 	if (isSignUpToggleOn) {
	// 		setIsSignInToggleOn(false);
	// 		setIsSignUpToggleOn(false);
	// 	}
	// 	setIsCreateToggleOn(!isCreateToggleOn);
	// 	setActionCompleted(false);
	// 	setProfileMenuVisible(false);
	// }

    const handleProfileClick = () => {
		if(isSignInToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		if(isSignUpToggleOn) {
			setIsSignInToggleOn(false);
			setIsSignUpToggleOn(false);
		}
		navigate('/profile/' + userId);
		setProfileMenuVisible(false);
	};

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setIsSignInToggleOn(true);
        setActionCompleted(false);
    };

    const handleCreateCompleted = () => {
		setActionCompleted(true);
		const id = setTimeout(() => {
			setIsCreateToggleOn(false);
			window.location.reload();
		}, 1000);
		setTimeoutId(id);
		if (window.location.pathname.includes("/profile") ) { 
            navigate('/profile/' + userId);
		} else {
			navigate(window.location.pathname);
		}
	}

    useEffect(() => {
        setHeader(props.header);
    }, [props.header]);

    useEffect(() => {
        setSubHeader(props.subHeader);
    }, [props.subHeader]);

    // Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    return (
        <section id="home" className="video-hero" data-section="home">
            <FormModal
                isToggleOn={isSignUpToggleOn}
                handleClick={handleSignUpClick}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title={messaging.sign_up.ui.title}
                successfulActionTitle={messaging.sign_up.ui.success.title}
                successfulActionMessage={
                    actionCompletedType === "signup_native_user" ? (
                        <div>{messaging.sign_up.ui.success.msg}</div>
                    ) : (
                        actionCompletedType === "signup_user" ? (
                            <h3>Welcome to sidejawn!</h3>
                            // <div>
                            //     <h3>Here are some things you can currently do:</h3>
                            //     <div className="boxes-wrapper">
                            //         <Module onClick={handleBrowseClick} image={multiProjectsIcon} imageDesc="sidejawn startup icon" header="Browse Startups" content="Browse startups and apply to ones that interest you." className="semi-clickable" />
                            //         <Module onClick={handleCreateClick} image={projectIcon} imageDesc="sidejawn startup icon" header="Post Your Startup" content="Organize your pitch to convince others to join your startup." className="semi-clickable" />
                            //         <Module onClick={handleProfileClick} image={profileIcon} imageDesc="sidejawn green silhouette person icon" header="View Your Profile" content="Convince people why they should work with you." className="semi-clickable"/>
                            //     </div>
                            //     <div className="horizontal-divider"></div>
                            // </div>
                        ) : null // Added null to handle the case where actionCompletedType doesn't match either condition
                    )
                }>
                <CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
            </FormModal>
            {/* <FormModal
				isToggleOn={isCreateToggleOn} 
				handleClick={handleCreateClick}
				actionCompleted={actionCompleted}
				onClose={() => clearTimeout(timeoutId)}
				modalType="project-card-modal"
				title="Post Startup"
				successfulActionTitle="Congratulations!"
				successfulActionMessage="You can access your post on your profile.">
				<ProjectCardForm
					handleActionCompleted={handleCreateCompleted}
					userMetadata={userMetadata}
					profileImage={userMetadata?.user_avatar ? formatAvatarUrl(userMetadata.user_avatar.url, userMetadata.user_avatar.updated_ts) : profileImage}
					editPostMode={false}
				/>
			</FormModal> */}
            <div className="display-t text-center">
                <div className="display-tc display-tc2 page-header-container">
                    <div className={"page-header-wrapper"}>
                        <div>
                            <p style={{color: "white"}} className="page-subheader display-linebreak">{preHeader}</p>
                            <h1 className="page-header display-linebreak">{header}</h1>
                            { props.mainPhoto === undefined ? null : (
                            <div className="page-image-header">
                                <video className='hero' alt="sidejawn-header-photo" autoPlay loop muted playsInline>
                                    <source src={props.mainPhoto} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            )}
                            {subHeader && subHeader !== '' && (
                                <p style={{color: "white"}} className="page-subheader display-linebreak">{subHeader}</p>
                            )}
                            <div style={{display:'flex', gap:'20px', justifyContent:'center'}}>
                                <Link to="/about">
                                    <Button className="page-header-btn btn-secondary" style={{color: "white", display: displayLearnMoreBtn}} onClick={handleClick}>
                                        About Us
                                    </Button>
                                </Link>
                                <Link to="/">
                                    <Button className="page-header-btn btn-secondary" style={{color: "white", display: displayGoHomeBtn}} onClick={handleClick}>
                                        Go Home
                                    </Button>
                                </Link>
                                {!authToken ? (
                                    <Button className="page-header-btn" variant="primary" onClick={handleSignUpClick}>
                                        Join us
                                    </Button>
                                ) : (
                                    <Link to="/discovery">
                                        <Button className="page-header-btn" style={{marginRight: "20px", display: displayDiscoveryBtn}} variant="primary" onClick={handleClick}>
                                            Browse Pitches
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default PageHeader;
