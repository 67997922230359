import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import blankprofileImage from 'images/blank-profile-picture.png'
import cameraIcon from 'images/icon-camera.png';
import classes from './UserProfileData.module.scss';
import AuthContext from 'app/AuthContext';
import { SmallProjectCard } from 'components';
import { FormModal, ProjectCardForm, PersonalMessageForm, CreateAccountForm, SignInForm, Button, SubMenuPopup } from 'sub-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
//import { SyncLoader } from 'react-spinners';
import { updateUserMetadata, getUserProfile, getUserProjects, processUserProfileData, getTitleAndGoal, calculateProfileCompletion, changeUserProfileAvatar, updateUserProfileStats } from 'lib/user';
import { getEnvConfig, formatAvatarUrl, getArticle, resizeImage, convertEpochToDate, sendEmail  } from 'lib/utils';
import flagIcon from "icons/small-flag-icon.png";
import { getUserContents, getContentsByCategory } from 'lib/content';
import { sendEventToAnalytics } from 'lib/reporting';
import Mail01 from "@untitled-ui/icons-react/build/cjs/Mail01";
import Share06 from "@untitled-ui/icons-react/build/cjs/Share06";

const UserProfileData = (props) => {	
  	// This is from the param in the URL
  	// Get userId from URL params if it exists, otherwise use the prop value
    const { userId: paramUserId } = useParams();
    const userId = paramUserId || props.userId;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
  
  	const [ profile, setProfile ] = useState({});
	const [profileCompPercent, setCompletionPercentage] = useState(0);
	const [ accomplishments, setAccomplishments ] = useState([]);
	const [profileAvatar, setProfileAvatar] = useState(profile.user_avatar ? formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts) : blankprofileImage);
	// const [profileVideo, setProfileVideo] = useState(profile.video_url);
	const [profileVideo, setProfileVideo] = useState(profile.video_url || 'https://sj-profile-video-dev.s3.amazonaws.com/christian-pitch.mp4');
	const [projects, setProjects] = useState([]);
	const [timeoutId, setTimeoutId] = useState(null);
	const [isEditProjectToggleOn, setEditProjectToggleOn] = useState(false);
	const [projectToEdit, setProjectToEdit] = useState(null);
	const [actionCompleted, setActionCompleted] = useState(false);
	const { authToken, userMetadata, logout, forceRefresh} = useContext(AuthContext);
	const loggedInUserId = userMetadata ? userMetadata.id : "";
	const [ displayedError, setDisplayedError ] = useState('');
	// const [isVideoAvailable, setIsVideoAvailable] = useState(!!profile.video_url); // Check if a video URL is provided
	const [isVideoAvailable, setIsVideoAvailable] = useState(true); // Check if a video URL is provided
    const [subMenuVisible, setSubMenuVisible] = useState(false);

    // Profile interactions
    const [isMessageModalToggleOn, setMessageModalToggle] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false); // State for notification

	// const location = useLocation();
	const [isProfileView, setIsProfileView] = useState(false); // Initialize with default value

	const [isSignUpToggleOn, setIsSignUpToggleOn] = useState(false);
    const [isSignInToggleOn, setIsSignInToggleOn] = useState(false);
	const [failedLoading, setFailedLoading] = useState(false);
	
	// Tabs to switch between background & impressive accomplishments
	const [activeTab, setActiveTab] = useState('background');

	// Get messaging info from the config files
    const messaging = getEnvConfig().messaging;

    // Alerting
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    // Hooks for flag popup confirmation
    const [showFlagPopup, setShowFlagPopup] = useState(false);
    const [profileFlagged, setProfileFlagged] = useState(false);
    const [flagConfirmationText, setFlagConfirmationText] = useState('Do you want to flag this profile for innappropriate content? \n Doing so will report the profile to our team for review.');

    // Share video function copies the profile link to the clipboard
    const shareVideo = () => {
        const currentProfileEndpoint = process.env.REACT_APP_ENVIRONMENT === "dev" ? "http://localhost:3000/profile/" : "https://www.sidejawn.io/profile/";
        const profileLink = currentProfileEndpoint + profile.id;

        // Check if navigator.clipboard is available
        if (navigator.clipboard) {
            navigator.clipboard.writeText(profileLink).then(() => {
                setNotificationVisible(true); // Show notification
                setTimeout(() => {
                    setNotificationVisible(false); // Hide notification after 2 seconds
                }, 2000);
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        } else {
            // Fallback method
            const textarea = document.createElement('textarea');
            textarea.value = profileLink;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy'); // Attempt to copy
                setNotificationVisible(true); // Show notification
                setTimeout(() => {
                    setNotificationVisible(false); // Hide notification after 2 seconds
                }, 2000);
            } catch (err) {
                console.error('Fallback: Unable to copy: ', err);
            }
            document.body.removeChild(textarea); // Clean up
        }
    };

    // Opens the messaging form only if they're signed in.
    const messagePerson = () => {
        if (authToken) {
            openMessageForm();
        } else {
            handleSignInClick();
        }
    };

    // Opens the personal message form
    const openMessageForm = () => {
        setMessageModalToggle(!isMessageModalToggleOn);
        setActionCompleted(false);
    };

    const handleMessagedCompleted = () => {
        setActionCompleted(true);
    }

	const handleSignUpClick = () => {
        setIsSignUpToggleOn(!isSignUpToggleOn);
        setActionCompleted(false);
    };

    const handleSignInClick = () => {
        setIsSignInToggleOn(!isSignInToggleOn);
        setActionCompleted(false);
    };

	const checkIfLoggedIn = () => {
        if (authToken) { 
            clearTimeout(timeoutId); 
        } else {
            setIsSignUpToggleOn(false);
            setIsSignInToggleOn(false);
			setEditProjectToggleOn(false);
        }
    }

    const handleTabClick = (tab) => {
        setActiveTab(tab); // Toggle tab state
    };

	// Edit button click handler for the user's profile
	const handleEditClick = () => {
		if (authToken) { 
			navigate('/profile/' + userId + '/edit');
		} else {
			handleSignInClick();
		}
	}

	const handleEditProjectClick = (index) => {
		setEditProjectToggleOn(!isEditProjectToggleOn);
		setProjectToEdit(projects[index]);
		if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Startup to edit: ", projects[index]); }
		setActionCompleted(false);
	}

	// Function to handle the completion of an action (e.g., editing a project)
	const handleActionCompleted = () => {
		setActionCompleted(true);
        setIsSignUpToggleOn(false);
        setIsSignInToggleOn(false);
		const id = setTimeout(() => {
			setEditProjectToggleOn(false);
		}, 1000);
		setTimeoutId(id);
	};

    const handleProfileSubMenuClick = (event) => {
        event.stopPropagation(); // Stop the click from bubbling up and triggering handleClickOutside
        setSubMenuVisible(!subMenuVisible); // Toggle visibility
    };
	
	// Function to display errors
	function displayError(message) {
        setAlertText(message);
        setShowAlert(true);
    }

	// Functions for popup handlers
	const handleOpenFlagPopup = () => {
		setShowFlagPopup(true);
	};

	const handleCloseFlagPopup = () => {
		setShowFlagPopup(false);
	};

	const handleFlagPopUpConfirm = () => {
		handleFlagClick();
		setFlagConfirmationText('Thank you for helping us keep our community safe. \nOur team will review, and take the appropriate action.');
	};

	const handleFlagPopupCancel = () => {
		handleCloseFlagPopup();
		setFlagConfirmationText('Do you want to flag this profile for innappropriate content? \nDoing so will report the profile to our team for review.');
	};

	async function handleFlagClick() {
		const flagData = {
			is_flagged: true
		}
		const { isSuccess: updateUserStatsSuccess, resp: updateUserStatsResp, err: updateUserStatsErr } = 
			await updateUserProfileStats(userId, flagData, authToken);
		if (updateUserStatsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Profile Stats Update || Success:", updateUserStatsResp); }
			setProfileFlagged(true);
			// Email metadata for notifiying the admin of the flagged profile
			const data = {
				name: userMetadata.display_name,
				email: 'iosif.christian@sidejawn.io',
				subject: 'sidejawn | New flagged profile',
				message: 'User: ' + userMetadata.display_name + ' Profile Link: http://localhost:3000/profile/' + userMetadata.id + ' has flagged the profile of: ' + profile.display_name + ' Profile Link: http://localhost:3000/profile/' + profile.id
			};
			const { isSuccess: sendFlagEmailSuccess, resp: sendFlagEmailResp, err: sendFlagEmailErr } = 
				await sendEmail(data);
			if (sendFlagEmailSuccess) {
				if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Send Flag Email || Success: ", sendFlagEmailResp); }
			} else {
				if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Send Flag Email || Error: ", sendFlagEmailResp, sendFlagEmailErr); }
				displayError(sendFlagEmailResp.message);
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Profile Stats Update || Error:", updateUserStatsResp, updateUserStatsErr); }
			if (updateUserStatsResp) {
				// Log out user if the token session is invalid (most likely expired).
				if (updateUserStatsResp.status_code === "UNAUTHORIZED") { 
					logout(); 
				} else {
					setDisplayedError("There was an error processing your request. Please try again later.");
				}
			} else {
				setDisplayedError("There was a fatal error updating processing your request. Please try again later.");
			}
		}
	}

	/* The above code is a JavaScript function called `loadUserContents` that is using the `useCallback`
	hook to asynchronously load a user's contents based on their `userId`. */
	const loadUserContents = useCallback(async (userId) => {
		// Get the user's accomplishments
		const { isSuccess: getContentsSuccess, resp: getContentsResp, err: getContentsErr } = await getUserContents(userId, [{ "key": "category", "value": "accompl" }], authToken);

		if (getContentsSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Content Search || Success", getContentsResp); }
			if (getContentsResp.results != null && getContentsResp.results) {
				// Update the state with the user's projects
				setAccomplishments(getContentsByCategory(getContentsResp.results, 'accompl'));
			} else {
				setAccomplishments([]);
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("User Content Search || Error", getContentsResp, getContentsErr); }
			if (getContentsResp) {
				// If unauthorized, log out the user's session
				if (getContentsResp.status_code === "UNAUTHORIZED") { logout(); }
			}
			// TODO: Handle error cases
		}
	}, []);

	/**
	 * The function `loadUserProfile` is an asynchronous function that loads a user's profile and updates
	 * the state with the profile data.
	 * @param userId - The `userId` parameter is the unique identifier of the user whose profile we want
	 * to load.
	 */
	const loadUserProfile = useCallback(async (userId, isProfileView) => {
		setLoading(true);

		// Check if the user is viewing their own profile
		if (loggedInUserId === userId) { setIsProfileView(true); }

		// Get the user's profile
		const { isSuccess: getProfileSuccess, resp: getProfileResp, err: getProfileErr } = 
			await getUserProfile( userId, [{ "key": "details", "value": "true" }, { "key": "tags", "value": "true" }], authToken );

		if (getProfileSuccess) {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Search || Success", getProfileResp); }
			// Process/format the user profile data
			getProfileResp.results = processUserProfileData(getProfileResp.results);
			// Update the state with the profile data
			setProfile(getProfileResp.results);
			// If the user is viewing their own profile, update the user metadata
			if (isProfileView) { 
				updateUserMetadata(getProfileResp.results.user_avatar, 'user_avatar');
				forceRefresh();
			}
		} else {
			if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Profile Search || Error", getProfileResp, getProfileErr); }
			if (getProfileResp) {
				// If unauthorized, log out the user's session
				if (getProfileResp.status_code === "UNAUTHORIZED") { 
					logout(); 
				} else if (getProfileResp.status_code === "NOT_FOUND") {
					navigate('/not-found');
				} else {
					setDisplayedError("There was an error retrieving your profile. Please try again later.");
				}
			} else {
				setDisplayedError("There was a fatal error retrieving your profile. Please try again later.");
			}
		}

    	setLoading(false);
	}, []);

	/**
	 * The function `loadUserProjects` is an asynchronous function that loads the projects of a user and
	 * handles success and error cases.
	 * @param userId - The `userId` parameter is the unique identifier of the user whose projects you want
	 * to load.
	 */
	// const loadUserProjects = useCallback(async (userId, isProfileView) => {

	// 	if (loggedInUserId === userId) { setIsProfileView(true); }

	// 	// Set the visibility types for the user's projects
	// 	let visibilityTypes = [{'key': 'visibility_types', 'value': 'public'}];
	// 	if (isProfileView) {
	// 		// If the user is viewing their own profile, show all projects
	// 		visibilityTypes = [{'key': 'visibility_types', 'value': 'public,private'}];
	// 	}
	// 	// Get the user's projects
	// 	const { isSuccess: getProjectsSuccess, resp: getProjectsResp, err: getProjectsErr } = await getUserProjects(userId, visibilityTypes, authToken);

	// 	if (getProjectsSuccess) {
	// 		if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("User Project Search || Success", getProjectsResp); }
	// 		if (getProjectsResp.results != null && getProjectsResp.results) {
	// 			// Update the state with the user's projects
	// 			setProjects(getProjectsResp.results);
	// 		} else {
	// 			setProjects([]);
	// 		}
	// 	} else {
	// 		if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("User Project Search || Error", getProjectsResp, getProjectsErr); }
	// 		if (getProjectsResp) {
	// 			// If unauthorized, log out the user's session
	// 			if (getProjectsResp.status_code === "UNAUTHORIZED") { logout(); }
	// 		}
	// 		// TODO: Handle error cases
	// 	}
	// }, []);

	/**
     * The function `uploadFile` is an asynchronous function that handles the uploading of a file,
     * specifically an avatar image, and updates the user's profile with the new avatar image.
     * @param event - The `event` parameter is an object that represents the event that triggered the
     * file upload. It is typically an event object that is passed to an event handler function when a
     * file is selected for upload. The `event.target` property refers to the element that triggered
     * the event, and `event.target.files
     */
    const uploadFile = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (process.env.REACT_APP_ENVIRONMENT !== "dev") { await sendEventToAnalytics("User", "user_change_avatar"); }
            setLoading(true);

            // Resize image, expect File object in return, not an actual file/image
            const resizedFileData = await resizeImage(file);
            
            let avatarId = null;
            // Add avatar ID
            if (typeof profile.user_avatar != 'undefined' 
                && profile.user_avatar
                && profile.user_avatar.id) {
                    avatarId = profile.user_avatar.id;
            }

            // Upload image
            const { isSuccess: changeAvatarSuccess, resp: changeAvatarResp, err: changeAvatarErr } = 
                await changeUserProfileAvatar(userId, avatarId, resizedFileData, authToken);

            if (changeAvatarSuccess) {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.log("Profile Image Upload || Success", changeAvatarResp); }
                
                setProfile(prevProfile => ({
                    ...prevProfile,
                    "user_avatar": changeAvatarResp.results.user_avatar
                }));

                // Update the profile image in the nav bar
                updateUserMetadata(changeAvatarResp.results.user_avatar, 'user_avatar');
                forceRefresh();
                setLoading(false);
            } else {
                if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Error uploading profile image:", changeAvatarErr); }
                if (changeAvatarResp) {
                    // If unauthorized, log out the user's session
                    if (changeAvatarResp.status_code === "UNAUTHORIZED") { logout(); }
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === "dev") { console.error("Empty response:", changeAvatarErr); }
                }
                setLoading(false);
                setFailedLoading(true);
            }
        } else {
            setFailedLoading(true);
        }
    };

	// Handle the click event for the portfolio links
	const handlePortfolioClick = (url) => {
		window.open(url, '_blank');
	}
	
	// useEffect(() => {
	// 	setProjects([]);
	// 	loadUserProjects(userId, isProfileView);
	// }, [userId, isProfileView, loadUserProjects]);

	useEffect(() => {
		setAccomplishments([]);
		loadUserContents(userId);
	}, [userId, loadUserContents]);
	
	useEffect(() => {
		if (loggedInUserId === userId) { setIsProfileView(true); }
		loadUserProfile(userId, isProfileView);
	}, [userId, isProfileView, loadUserProfile]);

	useEffect(() => {
		setCompletionPercentage(calculateProfileCompletion(profile));
	}, [profile]);

	useEffect(() => {
		if (profile.user_avatar?.url) {
			setProfileAvatar(formatAvatarUrl(profile.user_avatar.url, profile.user_avatar.updated_ts));
		}
	}, [profile.user_avatar]);

	return (
		<div className={classes.userProfileData}>
			<FormModal
				backdrop='static'
				isToggleOn={isSignUpToggleOn}
				handleClick={handleSignUpClick}
				onClose={checkIfLoggedIn}
				actionCompleted={actionCompleted}
				title={messaging.sign_up.ui.title}
				successfulActionTitle={messaging.sign_up.ui.success.title}
				successfulActionMessage={messaging.sign_up.ui.success.msg}>
				<CreateAccountForm handleSignInClick={handleSignInClick} onActionCompleted={handleActionCompleted} />
			</FormModal>
			<FormModal
				backdrop='static'
				isToggleOn={isSignInToggleOn}
				handleClick={handleSignInClick}
				onClose={checkIfLoggedIn}
				actionCompleted={actionCompleted}
				title={messaging.sign_in.ui.title}>
				<SignInForm handleSignUpClick={handleSignUpClick} onActionCompleted={handleActionCompleted} />
			</FormModal>
			{/* <FormModal
				isToggleOn={isEditProjectToggleOn}
				handleClick={handleEditProjectClick}
				actionCompleted={actionCompleted}
				onClose={() => clearTimeout(timeoutId)}
				title="Edit Post"
				successfulActionTitle="Congratulations!"
				successfulActionMessage="Your startup has been successfully updated."
				modalType="project-card-modal">
				{authToken && 
					<ProjectCardForm
					handleActionCompleted={handleActionCompleted}
					projectData={projectToEdit}
					editPostMode={true}
					userMetadata={userMetadata}
					profileImage={profileAvatar}
					loadUserProjects={loadUserProjects}
					/>
				}
			</FormModal> */}
            <FormModal
                isToggleOn={isMessageModalToggleOn}
                handleClick={openMessageForm}
                onClose={() => clearTimeout(timeoutId)}
                actionCompleted={actionCompleted}
                title="Send a Message"
                successfulActionTitle="Message Sent!"
                successfulActionMessage={"Check your email for next steps, and keep exploring! \n (Double check your junk mail just in case.)"}>
                <PersonalMessageForm
                    onActionCompleted={handleMessagedCompleted}
                    receiverId={profile.id}
                />
            </FormModal>
            {showFlagPopup && (
                //TODO: Use a reusable component for the following
                <div>
                    <div className="fade modal-backdrop show"></div>
                    <div
                    role="dialog"
                    aria-modal="true"
                    className="fade undefined modal show"
                    tabIndex="-1"
                    style={{ display: 'block' }}
                    >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">Report Confirmation</div>
                                <button type="button" className="close" onClick={handleFlagPopupCancel}>
                                <span aria-hidden="true">×</span>
                                <span className="sr-only"></span>
                                </button>
                        </div>
                        <div className="modal-body">
                            <div className={classes.modalInner}>
                                <div className="modal-inner-dialog display-linebreak">
                                {flagConfirmationText}
                                </div>
                                {!profileFlagged && (
                                <div className={classes.modalButtons}>
                                    <Button className="btn-secondary" onClick={handleFlagPopupCancel}>Cancel</Button>
                                    <Button className="btn-primary" type='submit' onClick={handleFlagPopUpConfirm}>Confirm</Button>
                                </div>
                                )}
                                {flagConfirmationText === 'You have successfully flagged this profile.' && (
                                    <div className={classes.modalButtons}>
                                        <Button variant="primary" onClick={handleFlagPopupCancel}>Close</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        </div>
                        <div hidden>
                            <div className="modal-header"></div>
                            <div className="message-modal basic-message modal-body"></div>
                        </div>
                    </div>
                    </div>
                </div>
            )}
            <div className={classes.profileContainer}>
                <div className={classes.profilePicSection}>
                    <div className={classes.profilePicInfo}>
                        <div className={classes.profilePicSubsection}>
                            <div className={classes.largeProfileCircle} style={{backgroundImage: `url(${profileAvatar})`}}>
                                { authToken && userMetadata && loggedInUserId === userId && (
                                    <div>
                                        <div className={classes.profilePictureWrapper}>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={uploadFile}
                                                style={{ display: 'none' }}
                                                id="fileUploader"
                                            />
                                            <label htmlFor="fileUploader" className={classes.uploadButton}>
                                                {loading ? 'Uploading...' : (failedLoading ? 'Failed, try again.' : '')}
                                            </label>
                                        </div>
                                        <button className={classes.cameraIconButton} onClick={() => document.getElementById('fileUploader').click()}>
                                            <img src={cameraIcon} alt="Camera Icon" />
                                        </button>
                                    </div>
                                )}
                            </div>
                            {loggedInUserId != userId && (
                                <div className={classes.subMenuSection}>
                                    <div className={classes.subMenu} onClick={handleProfileSubMenuClick}>
                                        <SubMenuPopup subMenuVisible={subMenuVisible} setSubMenuVisible={setSubMenuVisible}> 
                                                <ul>
                                                    <li>
                                                        <div className={classes.flagButton} onClick={handleOpenFlagPopup}>
                                                            <a>Report</a> <img className="small-icon" alt="Frame" src={flagIcon} style={{cursor:'pointer'}}/>
                                                        </div>
                                                    </li>
                                                </ul>
                                        </SubMenuPopup>
                                    </div>
                                </div>
                            )}
                            <h2>{(profile.display_name ? profile.display_name : '')}</h2>
                            <div className={classes.profilePicSubsection}>
                                <div className={classes.profileObjective}>
                                    {profile.tags && profile.tags.length > 0 ? (
                                        (() => {
                                            const { title: getTitle, goal: getGoal } = getTitleAndGoal(profile.tags);
                                            return `I am ${getTitle ? getArticle(getTitle) : 'a'} ${getTitle ? getTitle : 'person'}, and I want to ${getGoal ? getGoal : 'decide on a goal for being here'}.`;
                                        })()
                                        ) : (
                                        'I am a person, and I want to decide on a goal for being here.'
                                    )}
                                </div>
                                {profile.community && (
                                    <div className={classes.communityContainer}>
                                        Member of: <div className={classes.community}>{profile.community ? profile.community : ''}</div>
                                    </div>
                                )}
                                {profile.location && (
                                    <div className={classes.profileLocation}>{profile.location ? profile.location : ''}</div>
                                )}
                                {profile.age && (
                                    <div className={classes.profileAge}>{profile.age ? `Age: ${profile.age}` : ''}</div>
                                )}
                            </div>
                        </div>
                    <div>
                </div>
                </div>
                </div>
                <div className={classes.profilePicSubsectionButtonOptions}>
                    { authToken && userMetadata ?
                        (
                            // If the user is logged in, show the edit button if the profile is incomplete
                            loggedInUserId === userId ?
                            (
                                    profileCompPercent < 100 ? 
                                (
                                    <div className={classes.profilePicSubsectionButton}>
                                        <Button onClick={handleEditClick} className="btn-primary">
                                            Complete Profile
                                            <div className={classes.progressBarContainer}>
                                                <div
                                                    className={classes.progressBar}
                                                    style={{ width: `${profileCompPercent}%` }}
                                                />
                                            </div>
                                        </Button>
                                    </div>
                                )
                                : 
                                (
                                    // If the user is logged in, show the edit button because profile is complete
                                    <div className={classes.profilePicSubsectionButton}>
                                        <Button onClick={handleEditClick} className="btn-primary">
                                            Edit Profile
                                        </Button>
                                    </div>
                                )
                            )
                            : 
                            (
                                <div>
                                    <div className={classes.profilePicSubsectionButton}>
                                        <button className="video-interaction-button" 
                                        onClick={messagePerson}
                                        ><Mail01/></button>
                                        <button onClick={shareVideo} className="video-interaction-button" ><Share06/></button>
                                    </div>
                                </div>
                            )
                        )
                        :
                        (
                            // Show the incomplete profile label if the profile is incomplete
                            // !profile.title && !profile.location && !profile.age && (
                            <div className={classes.profilePicSubsectionButton}>
                                <Button>
                                        Not Logged In
                                </Button>
                            </div>
                            //)
                        )
                    }
                </div>
                <Alert variant="danger" className="animated-fast fadeInDownMenu">
                    <Alert.Heading style={{ fontSize: "13px", color: "red", whiteSpace: "pre-line" }}>
                        {displayedError}
                    </Alert.Heading>
                </Alert>
                <div className={classes.profileDetails}>
                    <div className='header-description-card'>
                        <h3>Introduction</h3>
                        <div className="divider"></div>
                        {profile.intro_summary ? (
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={profile.intro_summary} />
                        ): (
                            <div>(Remind me to fill out my intro.)</div>
                        )}
                    </div>
                    <div className={classes.portfolioSection}>
                        <h3>Portfolios</h3>
                        <div className={classes.portfolioLinksContainer}>
                            <div className={classes.portfolioLinksInnerContainer}>
                                {profile?.portfolios?.length > 0 ? (
                                    profile.portfolios.map((portfolio, index) => (
                                        <div className={classes.portfolioLink} key={index} onClick={() => handlePortfolioClick(portfolio?.url)} style={{backgroundImage: `url(/portfolio-icons/${portfolio?.icon}.png)`}}></div>
                                    ))
                                ) : (
                                    <div>(I should add an important portfolio soon.)</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={classes.tabs}>
                        <div
                            className={`${classes.tab} ${activeTab === 'background' ? classes.active : ''}`}
                            onClick={() => handleTabClick('background')}>
                            Background
                        </div>
                        <div className={classes.circleSeparator} />
                        <div
                            className={`${classes.tab} ${activeTab === 'skills' ? classes.active : ''}`}
                            onClick={() => handleTabClick('skills')}>
                            Skills+
                        </div>
                        <div className={classes.circleSeparator} />
                        <div
                            className={`${classes.tab} ${activeTab === 'accomplishments' ? classes.active : ''}`}
                            onClick={() => accomplishments?.length && handleTabClick('accomplishments')}>
                            Accomplishments
                            <div className={`${classes.numberCount} ${activeTab === 'accomplishments' ? classes.active : ''}`}>
                                {accomplishments?.length ? accomplishments?.length : 0}	
                            </div>
                        </div>
                    </div>
                    {activeTab === 'background' && (
                        <div className={classes.profileSection}>
                            <div className='header-description-card'>
                                <h3>Experience</h3>
                                <div className="divider"/>
                                {profile.experience ? (
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={profile.experience} />
                                ): (
                                    <div>(Remind me to summarize my experience please.)</div>
                                )}
                            </div>
                            { profile.education  && (
                                <div className='header-description-card'>
                                    <h3>Education</h3>
                                    <div className="divider"/>
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={profile.education} />
                                </div>
                            )}
                        </div>
                    )}
                    {activeTab === 'skills' && (
                        <div className={classes.profileSection}>
                            <div className='header-description-card'>
                            <h3>Skills & Expertises</h3>
                            <div className="divider"></div>
                            <div className={classes.skillsExpertiseSubsection}>
                                <div className={classes.skillsContainer}>
                                    {profile.tags && profile.tags.length > 0 ? (
                                        (() => {
                                        const skillTags = profile.tags
                                            .filter(tag => tag.type === 'skill')
                                            .flatMap(tag => tag.categories.flatMap(category => category.tags.map(skill => skill.display_name)));

                                        return skillTags.length > 0 ? (
                                            skillTags.map((skill, index) => (
                                            <div className={`${classes.tag} ${classes.skill}`} key={index}>{skill}</div>
                                            ))
                                        ) : (
                                            <div className={`${classes.tag} ${classes.skill}`}>(Remind me to add my skills.)</div>
                                        );
                                        })()
                                    ) : (
                                        <div className={`${classes.tag} ${classes.skill}`}>(Remind me to add my skills.)</div>
                                    )}
                                </div>
                                <div className={classes.expertiseContainer}>
                                    {profile.tags && profile.tags.length > 0 ? (
                                        (() => {
                                        const expertiseTags = profile.tags
                                            .filter(tag => tag.type === 'expertise')
                                            .flatMap(tag => tag.categories.flatMap(category => category.tags.map(expertise => expertise.display_name)));

                                        return expertiseTags.length > 0 ? (
                                            expertiseTags.map((expertise, index) => (
                                            <div className={`${classes.tag} ${classes.expertise}`} key={index}>{expertise}</div>
                                            ))
                                        ) : (
                                            <div className={`${classes.tag} ${classes.expertise}`}>(Remind me to add my expertise.)</div>
                                        );
                                        })()
                                    ) : (
                                        <div className={`${classes.tag} ${classes.expertise}`}>(Remind me to add my skills.)</div>
                                    )}
                                </div>
                                </div>
                            </div>
                            <div className='header-description-card'>
                                <h3>Interests</h3>
                                <div className="divider"></div>
                                <div className={classes.interestsSubsection}>
                                    <div className={classes.interestsContainer}>
                                        {profile.tags && profile.tags.length > 0 ? (
                                            (() => {
                                            const interestTags = profile.tags
                                                .filter(tag => tag.type === 'interest')
                                                .flatMap(tag => tag.categories.map(category => ({
                                                category: category.category,
                                                tags: category.tags
                                                })));

                                            return interestTags.length > 0 ? (
                                                interestTags.map((category, index) => (
                                                <div key={index}>
                                                    <h3>{category.category}</h3>
                                                    {category.tags.map((tag, idx) => (
                                                    <div className={`${classes.tag} ${classes.interest}`} key={idx} style={{ marginBottom: '10px' }}>
                                                        {tag.display_name}
                                                    </div>
                                                    ))}
                                                </div>
                                                ))
                                            ) : (
                                                <div className={`${classes.tag} ${classes.interest}`}>(I'm deciding my interests day by day.)</div>
                                            );
                                            })()
                                        ) : (
                                            <div className={`${classes.tag} ${classes.interest}`}>(I'm deciding my interests day by day.)</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTab === 'accomplishments' && (
                        <div className={classes.grayVerticalContainer}>
                            <div className={classes.personalProjectsContainer}>
                            <div className={`${classes.cardsFeed} ${classes.horizontalScrolling}`}>
                                {accomplishments?.map((accomplishment) => (
                                    <div key={accomplishment.id} className="header-description-card">
                                        <h3>{accomplishment.title}</h3>
                                        <div className="divider"></div>
                                        {accomplishment.body && (
                                            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={accomplishment.body} />
                                        )}
                                    </div>
                                ))}
                            </div>
                            </div>
                        </div>
                    )}	
                </div>
                {projects?.length === 0 || projects == null && (
                    <div className={classes.personalProjectsContainer}>
                    <div className="gray-container full-width">
                        <h2>{profile.f_name}'s Startups:</h2>
                        <div className={classes.personalProjects}>
                            <div className={`${classes.cardsFeed} ${classes.horizontalScrolling}`}>
                                {projects.length === 0 ? (
                                    // If the user has no projects, show no projects
                                    <div className={classes.projectsNotFoundMessage}>
                                        {profile.f_name} has not joined or started a startup yet.
                                    </div>
                                ) : (
                                    projects.map((project, index) => (
                                    <SmallProjectCard
                                        key={index}
                                        index={index}
                                        projectData={project}
                                        //Users should not be able to edit the projects of others
                                        editMode = {authToken && userMetadata && loggedInUserId === userId ? true: false}
                                        handleEditProjectClick = {handleEditProjectClick}
                                    />
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
            {/* Notification for link copied */}
            {notificationVisible && (
                <div className={classes.notification}>
                    Link copied to clipboard!
                </div>
            )}
		</div>
	);
};

export default UserProfileData;
